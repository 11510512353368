import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Seo from "../components/seo"
import Layout from "../components/layout"
import useWindowSize from "../Hooks/useWindowSize"
import GoBack from "../components/GoBack/GoBack"
import useViewport from "../Hooks/useViewport"

const boxShadow = {
  boxShadow:
    "rgba(0, 0, 0, 0.3) 2px 2px 6px -1px, rgba(0, 0, 0, 0.1) 2px 2px 4px -1px",
}

export const query = graphql`
  query ($slug: String!) {
    contentfulReportajes(url: { eq: $slug }) {
      titleSeo
      descriptionSeo {
        descriptionSeo
      }
      medio
      titular
      ano
      mes
      imagenes {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: DOMINANT_COLOR)
      }
      url
      portada {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: DOMINANT_COLOR)
      }
    }
  }
`

const Reportaje = props => {
  const size = useWindowSize()
  useViewport()

  return (
    <Layout>
      <Seo
        title={props.data.contentfulReportajes.medio}
        lang="es"
        titleSEO={props.data.contentfulReportajes.titleSeo}
        description={props.data.contentfulReportajes.descriptionSeo.descriptionSeo}
      />

      <div className="Reportaje">
        {size > 880 ? (
          <>
            <div className="row">
              <div className="col-11">
                <div className="Reportaje--title">
                  <h1>{props.data.contentfulReportajes.titular}</h1>
                  <h6>
                    {props.data.contentfulReportajes.medio}
                    {", "}
                    {props.data.contentfulReportajes.mes.toLowerCase()}{" "}
                    {props.data.contentfulReportajes?.ano || "2020"}
                  </h6>
                </div>
              </div>
              <div className="col-1">
                <GoBack />
              </div>
            </div>
            <div className="container Reportaje--imgs">
              <div className="row justify-content-center">
                {props.data?.contentfulReportajes?.imagenes?.length <= 1 && (
                  <GatsbyImage
                    image={getImage(
                      props.data?.contentfulReportajes?.portada.gatsbyImageData
                    )}
                    className="col-lg-6 col-md-6 mb-5 justify-content-center"
                    alt=""
                    style={boxShadow}
                  />
                )}
                {props.data?.contentfulReportajes?.imagenes?.map((img, i) => (
                  <GatsbyImage
                    key={i}
                    image={getImage(img)}
                    alt=""
                    className="col-lg-6 col-md-6 mb-5 justify-content-center"
                    style={boxShadow}
                  />
                ))}
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="Reportaje--title">
              <h1>{props.data.contentfulReportajes.titular}</h1>
              <h6>
                {props.data.contentfulReportajes.medio}
                {", "}
                {props.data.contentfulReportajes.mes.toLowerCase()}{" "}
                {props.data.contentfulReportajes?.ano || "2020"}
              </h6>
            </div>
            <div className="container Reportaje--imgs">
              {props.data?.contentfulReportajes?.imagenes?.length <= 1 && (
                <GatsbyImage
                  image={getImage(
                    props.data?.contentfulReportajes?.portada.gatsbyImageData
                  )}
                  className="col-lg-6 col-md-6 mb-3 justify-content-center"
                  alt=""
                />
              )}
              {props.data?.contentfulReportajes?.imagenes?.map((img, i) => (
                <GatsbyImage
                  key={i}
                  image={getImage(img)}
                  alt=""
                  className="col-lg-6 col-md-6 mb-3 justify-content-center"
                />
              ))}
            </div>
          </>
        )}
      </div>
    </Layout>
  )
}

export default Reportaje
