import * as React from "react"

import Footer from "./Footer/Footer"
import Menu from "./Menu/Menu"
import useViewport from "../Hooks/useViewport";
import "./layout.scss"

const Layout = ({ children }) => {
  useViewport();
  return (
    <div className="Layout">
      <Menu />
      <main>{children}</main>
      <Footer />
    </div>
  )
}

export default Layout
