import React from "react"
import { Link } from "gatsby"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const useLogo = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulLogo {
        edges {
          node {
            logo {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
            }
          }
        }
      }
    }
  `)

  return data.allContentfulLogo.edges[0].node
}

const Menu = () => {
  const data = useLogo()

  const onClick = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      left: 0,
      behavior: "smooth",
    })
  }

  return (
    <>
      <div className="MenuXL__brand">
      <Link className="MenuXL__brand__container" to="/">
          <GatsbyImage image={getImage(data.logo)} alt="Estudio" className="MenuXL__brand__container--logoImg"/>
        </Link>
      </div>

      <div className="MenuXL__content">
        <Link
          partiallyActive={true}
          className="MenuXL__content--navlink"
          activeClassName="MenuXL__content--active"
          to="/proyectos"
        >
          Proyectos
        </Link>

        <Link
          className="MenuXL__content--navlink"
          activeClassName="MenuXL__content--active"
          to="/estudio"
        >
          Estudio
        </Link>

        <Link
          partiallyActive={true}
          className="MenuXL__content--navlink"
          activeClassName="MenuXL__content--active"
          to="/reportajes"
        >
          Reportajes
        </Link>

        <button className="MenuXL__content--navlink" onClick={onClick}>
          Contacto
        </button>
      </div>
    </>
  )
}

export default Menu
